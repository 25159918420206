import { makeAutoObservable } from 'mobx';
import type { RootStore } from './rootStore';

export default class BoardWorkerStore {
  private readonly rootStore: RootStore;

  isWorkerInitialized: boolean = false;
  isWorkerActive: boolean = false;
  isWorkerTerminated: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;
  }

  setWorkerInitialized = (value: boolean) => {
    this.isWorkerInitialized = value;
  };

  setWorkerStatus = (value: boolean) => {
    this.isWorkerActive = value;
  };

  setWorkerTerminated = (isTerminated: boolean) => {
    this.isWorkerTerminated = isTerminated;
  };

  resetWorkerStatus = () => {
    this.isWorkerInitialized = false;
    this.isWorkerActive = false;
    this.isWorkerTerminated = false;
  };
}
