import styled from '@emotion/styled';

export const TitleContainer = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  background: white;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
