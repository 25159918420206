import React, { useRef, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from 'stores/StoreProvider';
import { CanvasBoardContainer } from './styles';
import { Draggable } from '../Draggable';
import { Sticker } from '../Sticker';
import { AppLoader } from 'ui/AppLoader';

export const CanvasBoard = observer(() => {
  const { stickerStore, boardStore } = useRootStore();

  const { sticker, moveSticker } = stickerStore;
  const { isStickerDragging } = stickerStore;
  const { boardConfigParsed, isStickerListLoading, stickerList } = boardStore;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isStickerDraggingRef = useRef(isStickerDragging);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const handleDragEnd = (x: number, y: number) => {
    moveSticker(x, y);
  };

  const updateZoomToFitHeight = () => {
    if (!boardConfigParsed) return;

    const { boardHeight } = boardConfigParsed;
    const viewportHeight = window.innerHeight;

    const initialZoomLevel = viewportHeight / boardHeight;
    setZoomLevel(initialZoomLevel);
  };

  const handleWheel = (event: any) => {
    event.preventDefault();
    const delta = event.deltaY > 0 ? -0.1 : 0.1;
    setZoomLevel((prevZoomLevel) => Math.max(0.1, prevZoomLevel + delta));
  };

  const startPan = (clientX: number, clientY: number) => {
    if (isStickerDraggingRef.current) return;

    const startX = clientX - offset.x;
    const startY = clientY - offset.y;

    const onMove = (moveEvent: MouseEvent | TouchEvent) => {
      const moveX =
        (moveEvent as MouseEvent).clientX ?? (moveEvent as TouchEvent).touches[0].clientX;
      const moveY =
        (moveEvent as MouseEvent).clientY ?? (moveEvent as TouchEvent).touches[0].clientY;

      setOffset({
        x: moveX - startX,
        y: moveY - startY,
      });
    };

    const endPan = () => {
      window.removeEventListener('mousemove', onMove);
      window.removeEventListener('mouseup', endPan);
      window.removeEventListener('touchmove', onMove);
      window.removeEventListener('touchend', endPan);
    };

    window.addEventListener('mousemove', onMove);
    window.addEventListener('mouseup', endPan);
    window.addEventListener('touchmove', onMove);
    window.addEventListener('touchend', endPan);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    if (event.touches.length === 1) {
      event.preventDefault();
      startPan(event.touches[0].clientX, event.touches[0].clientY);
    }
  };

  const handleMouseDown = (event: any) => {
    const startX = event.clientX - offset.x;
    const startY = event.clientY - offset.y;

    const onMouseMove = (moveEvent: any) => {
      if (isStickerDraggingRef.current) return;

      const newOffset = {
        x: moveEvent.clientX - startX,
        y: moveEvent.clientY - startY,
      };
      setOffset(newOffset);
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  useEffect(() => {
    isStickerDraggingRef.current = isStickerDragging;
  }, [isStickerDragging]);

  useEffect(() => {
    updateZoomToFitHeight();

    window.addEventListener('resize', updateZoomToFitHeight);
    return () => window.removeEventListener('resize', updateZoomToFitHeight);
  }, [boardConfigParsed]);

  useEffect(() => {
    if (!boardConfigParsed) return;

    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, boardConfigParsed?.boardWidth, boardConfigParsed?.boardHeight);
    }
  }, []);

  return (
    <CanvasBoardContainer
      width={boardConfigParsed?.boardWidth}
      height={boardConfigParsed?.boardHeight}
      onWheel={handleWheel}
      onMouseDown={handleMouseDown}
      //onTouchStart={handleTouchStart}
      style={{
        transform: `scale(${zoomLevel})`,
        left: offset.x,
        top: offset.y,
      }}
    >
      <canvas
        ref={canvasRef}
        width={boardConfigParsed?.boardWidth}
        height={boardConfigParsed?.boardHeight}
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      <>
        {isStickerListLoading && <AppLoader />}
        {stickerList.map((sticker) => (
          <Sticker key={sticker.id} item={sticker} />
        ))}
        {sticker && <Draggable item={sticker} onDragEnd={handleDragEnd} />}
      </>
    </CanvasBoardContainer>
  );
});
