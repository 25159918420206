import { Typography } from '@mui/material';
import React from 'react';
import './styles.css';

interface ColorPickerProps {
  label: string;
  color: string;
  onChange: (color: string) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ label, color, onChange }) => {
  return (
    <div className='container'>
      <Typography variant='body1'>{label}</Typography>
      <input
        type='color'
        value={color}
        onChange={(e) => onChange(e.target.value)}
        style={{ cursor: 'pointer', border: 'none' }}
      />
    </div>
  );
};
