import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { EColors } from 'constants/colors';

const DRAWER_WIDTH = '400px';

export const DrawerContainer = styled.div<{ open: boolean; $isHidden: boolean }>`
  width: ${DRAWER_WIDTH};
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  top: 0;
  right: ${({ open }) => (open ? '0' : `calc(${DRAWER_WIDTH} * -1.3)`)};
  background: ${EColors.White};
  transition: right 0.2s ease-in-out;
  box-shadow: -1px 0 2px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.spacing(2)};
  opacity: ${({ $isHidden }) => ($isHidden ? 0.1 : 1)};
  pointer-events: ${({ $isHidden }) => ($isHidden ? 'none' : 'auto')};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  margin: auto 0 0 0;
`;

export const SubmitBtn = styled(Button)`
  width: 100%;
`;

export const CreateStickerFormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};
`;
