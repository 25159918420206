import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { StickerStyled } from 'ui/stickerStyles';
import { EColors } from 'constants/colors';

const pulsate = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

export const DraggableContainer = styled(StickerStyled)<{ isStickerDragging?: boolean }>`
  position: absolute;
  cursor: grab;
  user-select: none;
  align-text: center;

  .fit {
    display: inline-block;
    width: 100%;
    align-text: center;
    word-break: break-word;
    white-space: nowrap;
  }
  .span {
    padding: 25;
  }

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 10px solid ${EColors.Blue};
    border-radius: 8px;
    box-sizing: border-box;
    animation: ${pulsate} 1.8s linear infinite;
    pointer-events: none;
  }

  ${({ isStickerDragging }) =>
    isStickerDragging &&
    `

  `}
`;
