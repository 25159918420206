import { CanvasBoard } from 'components/canvas/CanvasBoard';
import { StickerCreateDrawer } from 'components/StickerCreateDrawer';

import { observer } from 'mobx-react-lite';
import React from 'react';

const Main = observer(() => {
  return (
    <>
      <StickerCreateDrawer />
      <CanvasBoard />
    </>
  );
});

export default Main;
