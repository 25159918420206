import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Resize } from 'assets/images/resize.svg';

interface IResizeIcon {
  handleResizeMouseDown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ResizeIcon: FC<IResizeIcon> = observer(({ handleResizeMouseDown }) => (
  <div
    className='resize-handle'
    onMouseDown={(event) => handleResizeMouseDown(event)}
    style={{
      width: '10px',
      height: '10px',
      position: 'absolute',
      right: '11px',
      bottom: '9px',
      cursor: 'se-resize',
    }}
  >
    <Resize />
  </div>
));
