import React, { FC } from 'react';
import { TitleContainer } from './styles';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ITitleWithActions {
  title: string;
  onClick: () => void;
}

export const TitleWithActions: FC<ITitleWithActions> = ({ title, onClick }) => {
  return (
    <TitleContainer>
      <Typography variant='h5'>{title}</Typography>
      <IconButton size='small' disableRipple onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </TitleContainer>
  );
};
