import styled from '@emotion/styled';
import { Modal } from '@mui/material';

export const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
  height: 600px;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const ModalContent = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  flex: 1;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;
