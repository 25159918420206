import axiosInstance from 'services/axiosInstance';
import {
  IBoard,
  IGetStickerPriceParams,
  IPostStickerParams,
  ISticker,
} from './interfaces';

const BASE = 'v1/sticker';

export const getStickerList = async () => {
  return await axiosInstance.get<ISticker[]>(`${BASE}/board`);
};

export const postSticker = async (params: IPostStickerParams) => {
  return await axiosInstance.post(`${BASE}/create`, params);
};

export const getBoardConfig = async () => {
  return await axiosInstance.get<IBoard>(`${BASE}/board-config`);
};

export const getStickerPrice = async (params: IGetStickerPriceParams) => {
  return await axiosInstance.get<number>(`${BASE}/calculate-price`, { params });
};
