import styled from '@emotion/styled';
import { EColors } from 'constants/colors';
import { ECanvas } from 'constants/main';

export const CanvasBoardContainer = styled.div<{ height?: number; width?: number }>`
  width: ${({ width }) => width || 0}px;
  height: ${({ height }) => height || 0}px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid ${EColors.Secondary};
  position: relative;
  transform-origin: top left;
  top: ${ECanvas.Padding}px;
  border-radius: 8px;
  margin: 0 auto;
`;
