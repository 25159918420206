import './global.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import Routes from './routes';
import { Header } from 'components/Header';
import { ToastContainer } from 'react-toastify';
import { useBoardWorker } from 'hooks/useBoardWorker';

function App() {
  const { initPolling } = useBoardWorker();

  useEffect(() => {
    initPolling();
  }, [initPolling]);

  return (
    <div>
      <ToastContainer limit={3} position='top-center' autoClose={3000} />
      <Header />
      <Routes />
    </div>
  );
}

export default App;
