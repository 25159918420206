import { createTheme } from '@mui/material/styles';
import { EColors } from 'constants/colors';
import { EThemeConfig } from 'constants/themeConfig';

export const theme = createTheme({
  palette: {
    primary: {
      main: EColors.Primary,
    },
    secondary: {
      main: EColors.Secondary,
    },
  },
  spacing: (factor: number) => `${8 * factor}px`,
  typography: {
    fontFamily: '"Exo 2", sans-serif',
    h5: {
      fontSize: EThemeConfig.FontH5,
      fontWeight: 600,
    },
    subtitle1: {
      color: EColors.TextSecondary,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          height: '16px',
          width: '16px',
          '&:hover, &:active, &:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
