/* eslint-disable @typescript-eslint/return-await */
import Main from 'pages/Main';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';

export const routeList: RouteObject[] = [
  {
    path: '/',
    children: [
      { index: true, element: <Main /> },
      { path: '*', element: <Navigate to='/' replace /> },
    ],
  },
];

const Routes = () => {
  const routes = useRoutes(routeList);
  return routes;
};

export default Routes;
