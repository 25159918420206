import { makeAutoObservable } from 'mobx';
import StickerStore from './stickerStore';
import BoardStore from './boardStore';
import BoardWorkerStore from './boardWorkerStore';

export class RootStore {
  stickerStore: StickerStore;
  boardStore: BoardStore;
  boardWorkerStore: BoardWorkerStore;

  isDrawerOpen: boolean = false;
  isPaymentModalOpen: boolean = false;
  isAppLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);

    this.boardStore = new BoardStore(this);
    this.stickerStore = new StickerStore(this);
    this.boardWorkerStore = new BoardWorkerStore(this);

    this.init();
  }

  setDrawerOpen = (value: boolean) => {
    this.isDrawerOpen = value;
  };

  setPaymentModalOpen = (value: boolean) => {
    this.isPaymentModalOpen = value;
  };

  setAppLoading = (value: boolean) => {
    this.isAppLoading = value;
  };

  closeModal = () => {
    this.setDrawerOpen(false);
    this.setPaymentModalOpen(false);
  };

  init = () => {
    this.boardStore.fetchBoardConfig();
  };
}

const rootStore = new RootStore();
export default rootStore;
