import axios from 'axios';

const API_URL = 'https://api.x0.pics/';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  },
);

export default axiosInstance;
