import type { RootStore } from 'stores/rootStore';
import rootStore from 'stores/rootStore';
import type { FC } from 'react';
import React, { createContext, useContext } from 'react';

const RootStoreContext = createContext<RootStore>(rootStore);

export const RootStoreProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>
  );
};

export const useRootStore = () => useContext(RootStoreContext);
