import { makeAutoObservable } from 'mobx';
import { getBoardConfig, getStickerList } from 'services/stickers';
import { IBoard, ISticker } from 'services/stickers/interfaces';
import { RootStore } from 'stores/rootStore';

export default class BoardStore {
  private rootStore: RootStore;

  boardConfig: IBoard | null = null;

  isStickerListLoading: boolean = false;
  stickerList: ISticker[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.rootStore = rootStore;

    this.init();
  }

  get boardConfigParsed() {
    if (!this.boardConfig) return null;

    return Object.fromEntries(
      Object.entries(this.boardConfig).map(([key, value]) => [key, Number(value)]),
    );
  }

  setStickerListLoading = (value: boolean) => {
    this.isStickerListLoading = value;
  };

  setStickerList = (newList: ISticker[]) => {
    this.stickerList = newList;
  };

  setBoardConfig = (config: IBoard | null) => {
    this.boardConfig = config;
  };

  fetchStickerBoard = async ({ isPolling }: { isPolling?: boolean } = {}) => {
    !isPolling && this.setStickerListLoading(true);
    try {
      const { data } = await getStickerList();
      this.setStickerList(data);
    } catch (error) {
      console.error('Error loading stickers:', error);
    } finally {
      !isPolling && this.setStickerListLoading(false);
    }
  };

  fetchBoardConfig = async () => {
    this.rootStore.setAppLoading(true);
    try {
      const { data } = await getBoardConfig();

      this.setBoardConfig(data);
    } catch (error) {
      console.error('Error loading board-config:', error);
    } finally {
      this.rootStore.setAppLoading(false);
    }
  };

  init = async () => {
    await this.fetchStickerBoard();
  };
}
