import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RootStoreProvider } from 'stores/StoreProvider';
import { theme } from './theme';
import { ThemeProvider } from '@emotion/react';

import { Theme as MuiTheme } from '@mui/material/styles';

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <RootStoreProvider>
        <App />
      </RootStoreProvider>
    </ThemeProvider>
  </BrowserRouter>,
);
