import { EWorkerMsgType } from 'constants/main';
import { useCallback, useEffect, useRef } from 'react';
import { useRootStore } from 'stores/StoreProvider';
import Worker from 'web-worker';

export const useBoardWorker = () => {
  const store = useRootStore();
  const workerRef = useRef<Worker | null>(null);

  const { boardWorkerStore, boardStore } = store;

  const {
    isWorkerInitialized,
    isWorkerActive,
    isWorkerTerminated,
    setWorkerInitialized,
    setWorkerStatus,
    setWorkerTerminated,
    resetWorkerStatus,
  } = boardWorkerStore;

  const { fetchStickerBoard } = boardStore;

  const setupWorkerListeners = (worker: Worker) => {
    worker.onmessage = (event: any) => {
      const { type, error } = event.data;
      switch (type) {
        case EWorkerMsgType.Start:
          setWorkerStatus(true);
          break;
        case EWorkerMsgType.Error:
          setWorkerStatus(false);
          break;
        case EWorkerMsgType.Stopped:
          setWorkerStatus(false);
          break;
        case EWorkerMsgType.Fetch:
          void fetchStickerBoard({ isPolling: true });
          break;
        default:
          setWorkerStatus(false);
          break;
      }
    };

    worker.onerror = (error: ErrorEvent) => {
      setWorkerStatus(false);
      setWorkerTerminated(true);
    };
  };

  const initializeWorker = useCallback(() => {
    workerRef.current = new Worker(
      new URL('../workers/boardWorker.worker.js', import.meta.url),
    );

    setupWorkerListeners(workerRef.current);
  }, [setupWorkerListeners]);

  useEffect(() => {
    if (workerRef.current) return;

    initializeWorker();

    return () => {
      if (workerRef.current) {
        cleanup();
      }
    };
  }, []);

  const startPolling = () => {
    if (isWorkerActive) return;

    workerRef.current?.postMessage({
      type: EWorkerMsgType.Start,
    });

    if (isWorkerTerminated) return;

    setWorkerStatus(true);
  };

  const initPolling = () => {
    if (isWorkerInitialized || isWorkerTerminated) return;

    setWorkerInitialized(true);
    setWorkerStatus(true);
    workerRef.current?.postMessage({ type: EWorkerMsgType.Start });
  };

  const stopPolling = () => {
    setWorkerStatus(false);
    workerRef.current?.postMessage({ type: EWorkerMsgType.Stop });
  };

  const terminatePolling = () => {
    setWorkerStatus(false);
    setWorkerTerminated(true);

    workerRef.current?.terminate();
  };

  const cleanup = useCallback(() => {
    stopPolling();
    terminatePolling();
    workerRef.current = null;
    resetWorkerStatus();
  }, [stopPolling, terminatePolling, workerRef, resetWorkerStatus]);

  return { initPolling, startPolling, stopPolling, terminatePolling };
};
